<template>
  <v-dialog v-model="load" persistent width="300">
    <v-card color="primary" dark>
      <v-card-text>
        載入資料中...
        <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'LoadingDialog',
  props: ['load'],
}
</script>
